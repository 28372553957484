:root {
    --header-height: 120px;
}

/*============ Main Container ============*/

.main-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

/*============ header style ============*/

#header {
    position: relative;
    z-index: 999;
    /* position: fixed;
    top: 0;
    right: 0;
    left: 280px; */
    height: var( --header-height);
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}

.header-inner {
    /* background: url(../images/header-bg.png);
    background-size: 100%;
    position: relative;
    overflow: hidden; */
    height: var( --header-height);
    background-repeat: no-repeat;
}

.search-section {
    padding: 20px 32px;
}

.search-section .form-group {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
}

.search-section .form-group .search-button {
    background: transparent;
    border: 1px solid transparent;
    color: #2a4722;
    font-size: 20px;
    position: absolute;
    top: 12px;
    left: 6px;
}
.search-section .form-group .advanced-search:focus,
.search-section .form-group .advanced-search:hover,
.search-section .form-group .advanced-search {
    color: #305328;
    font-size: 20px;
    font-weight: 500;
    position: absolute;
    top: 14px;
    right: 20px;
    text-decoration: underline;
    font-family: 'Hind Siliguri', sans-serif;
    display: block;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
}

.search-section .form-group .search-control {
    background-color: rgba(255, 255, 255, 0.8);
    padding-left: 40px;
    padding-right: 150px;
    color: #305328;
    font-size: 20px;
    font-weight: 300;
    height: 48px;
}

.header-logo {
    text-align: center;
    max-width: 24vw;
    margin: 32px auto auto auto;
}

/*============ sidebar style ============*/

.sidebar {
    width: 280px;
    height: 100vh;
    position: fixed;
    /* background: #ffffff; */
    background: #f4f7f9;
    overflow: hidden;
    top: 0;
    bottom: 0;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
}

.logo {
    padding: 20px 20px 32px 20px;
    height: 132px;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}

.logo a {
    display: block;
    max-width: 210px;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}

.logo a img {
    max-width: 210px;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}

.menu-toggle-box {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 5px 20px;
}

.menu-heading {
    color: #2a4722;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Hind Siliguri', sans-serif;
}

.menu-button button {
    border: 1px solid transparent;
    background-color: transparent;
    font-size: 20px;
    color: #2a4722;
    padding: 0;
    margin: 0;
}

.navbar-item {
    padding-bottom: 60px;
}

/* .navbar-item ul li a {
    display: flex;
    color: #894306;
    font-size: 18px;
    font-weight: 400;
    padding: 10px 10px 10px 20px;
    align-items: center;
    border-left: 4px solid transparent;
    font-family: 'Hind Siliguri', sans-serif;
} */

.navbar-item ul li {
    position: relative;
}

.navbar-item ul li a {
    display: flex;
    color: #894306;
    font-size: 18px;
    font-weight: 400;
    padding: 10px 10px 10px 20px;
    align-items: center;
    border-left: 4px solid transparent;
    font-family: 'Hind Siliguri', sans-serif;
}

.navbar-item ul li a i.item-icon {
    display: inline-block;
    margin-right: 15px;
    width: 24px;
    height: 24px;
    background-image: url(../images/nav-icon/01.png);
    background-repeat: no-repeat;
    text-align: center;
    align-content: space-between;
    background-size: 100%;
}

.navbar-item ul li.mm-active a, .navbar-item ul li a.active,
 .navbar-item ul li a:hover {
    border-left: 4px solid #2a4722;
    /* background-color: #f0f7f1; */
    background-color: #ffffff;
    color: #2a4722;
}

.navbar-item ul li a.active i.item-icon.icon-1,
 .navbar-item ul li a:hover i.item-icon.icon-1 {
    background-image: url(../images/nav-icon/01-h.png);
}

.navbar-item ul li a.active i.item-icon.icon-2,
 .navbar-item ul li a:hover i.item-icon.icon-2 {
    background-image: url(../images/nav-icon/01-h.png);
}

.navbar-item ul li a i.item-icon.icon-3 {
    background-image: url(../images/nav-icon/03.png);
}

.navbar-item ul li a.active i.item-icon.icon-3,
.navbar-item ul li a:hover i.item-icon.icon-3 {
    background-image: url(../images/nav-icon/03-h.png);
}

.navbar-item ul li a i.item-icon.icon-4 {
    background-image: url(../images/nav-icon/04.png);
}

.navbar-item ul li a.active i.item-icon.icon-4,
.navbar-item ul li a:hover i.item-icon.icon-4 {
    background-image: url(../images/nav-icon/04-h.png);
}

.navbar-item ul li a i.item-icon.icon-5 {
    background-image: url(../images/nav-icon/05.png);
}

.navbar-item ul li a.active i.item-icon.icon-5,
.navbar-item ul li a:hover i.item-icon.icon-5 {
    background-image: url(../images/nav-icon/05-h.png);
}

.navbar-item ul li a i.item-icon.icon-6 {
    background-image: url(../images/nav-icon/06.png);
}

.navbar-item ul li a.active i.item-icon.icon-6, 
.navbar-item ul li a:hover i.item-icon.icon-6 {
    background-image: url(../images/nav-icon/06-h.png);
}

.navbar-item ul li a i.item-icon.icon-7 {
    background-image: url(../images/nav-icon/07.png);
}

.navbar-item ul li a.active i.item-icon.icon-7,
 .navbar-item ul li a:hover i.item-icon.icon-7 {
    background-image: url(../images/nav-icon/07-h.png);
}

.navbar-item ul li a i.item-icon.icon-8 {
    background-image: url(../images/nav-icon/08.png);
}

.navbar-item ul li a.active i.item-icon.icon-13,
.navbar-item ul li a:hover i.item-icon.icon-13 {
    background-image: url(../images/nav-icon/013-h.png);
}

.navbar-item ul li a i.item-icon.icon-13 {
    background-image: url(../images/nav-icon/013.png);
}

.navbar-item ul li a.active i.item-icon.icon-8,
.navbar-item ul li a:hover i.item-icon.icon-8 {
    background-image: url(../images/nav-icon/08-h.png);
}

.navbar-item ul li a i.item-icon.icon-9 {
    background-image: url(../images/nav-icon/09.png);
}

.navbar-item ul li a.active i.item-icon.icon-9,
.navbar-item ul li a:hover i.item-icon.icon-9 {
    background-image: url(../images/nav-icon/09-h.png);
}

.navbar-item ul li a i.item-icon.icon-9 {
    background-image: url(../images/nav-icon/09.png);
}

.navbar-item ul li a.active i.item-icon.icon-9,
.navbar-item ul li a:hover i.item-icon.icon-9 {
    background-image: url(../images/nav-icon/09-h.png);
}

.navbar-item ul li a i.item-icon.icon-10 {
    background-image: url(../images/nav-icon/010.png);
}

.navbar-item ul li a.active i.item-icon.icon-10,
.navbar-item ul li a:hover i.item-icon.icon-10 {
    background-image: url(../images/nav-icon/010-h.png);
}

.navbar-item ul li a i.item-icon.icon-11 {
    background-image: url(../images/nav-icon/011.png);
}

.navbar-item ul li a.active i.item-icon.icon-11,
.navbar-item ul li a:hover i.item-icon.icon-11 {
    background-image: url(../images/nav-icon/011-h.png);
}

.navbar-item ul li a i.item-icon.icon-12 {
    background-image: url(../images/nav-icon/012.png);
}

.navbar-item ul li a.active i.item-icon.icon-12,
.navbar-item ul li a:hover i.item-icon.icon-12 {
    background-image: url(../images/nav-icon/012-h.png);
}

.navbar-item ul li a i.item-icon.icon-14 {
    background-image: url(../images/nav-icon/014.png);
}
.navbar-item ul li a.active i.item-icon.icon-14,
.navbar-item ul li a:hover i.item-icon.icon-14 {
    background-image: url(../images/nav-icon/014-h.png);
}

.navbar-item ul li a i.item-icon.icon-search {
    background-image: url(../images/nav-icon/search.png);
}

.navbar-item ul li a.active i.item-icon.icon-search,
.navbar-item ul li a:hover i.item-icon.icon-search {
    background-image: url(../images/nav-icon/search-active.png);
}

.navbar-item .sub-menu {
    background-color: #fff;
    width: 100%;
}

.navbar-item .sub-menu li a {
    color: #555 !important;
    font-size: 16px;
    border-left: 0 !important;
    padding-left: 60px;
}

.navbar-item .sub-menu li a i {
    font-size: 13px !important;
    display: inline-block;
    position: relative;
    margin-right: 12px;
    top: -1px;
}

.navbar-item .sub-menu li a:hover {
    border-left: 0 !important;
    color: #894306 !important;
    ;
}

/*============ main content style ============*/

.main-content {
    height: 100vh;
    position: fixed;
    overflow: hidden;
    left: 280px;
    top: 0;
    bottom: 0;
    right: 0;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
    background: url(../images/header-bg.png);
    background-size: 100% var(--header-height);
    background-repeat: no-repeat;
}

.wrapper {
    padding: 0 20px;
    position: relative;
    overflow: hidden;
}

.para-title-box {
    display: flex;
    justify-content: space-between;
}
.para-title {
    font-size: 24px;
    color: #15172a;
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
    padding: 5px;
    font-family: 'Hind Siliguri', sans-serif;
}
.hadith-name-list ul{
    display: flex;
}
.hadith-name-list ul li {
    font-size: 24px;
    color: #15172a;
    font-weight: 500;
    margin-bottom: 10px;
    padding: 5px 3px;
    font-family: 'Hind Siliguri', sans-serif;
    cursor: pointer;
}

.para-title span {
    color: #965924;
}

.surah-item, .para-item {
    /* padding: 3px; */
    margin: 3px;
    height: 100%;
    background: #fff;
    padding: 1px;
    box-shadow: 0 2px 3px 2px #f2f2f2;
    border-radius: 4px;
}

.surah-item ul, .para-item ul {
    position: relative;
    overflow: hidden;
    background: #fff;
    /* padding: 1px;
    box-shadow: 0 2px 3px 2px #f2f2f2;
    border-radius: 4px; */
}

.surah-item ul li, 
.para-item ul li {
    display: flex;
    align-items: center;
    padding: 8px 8px;
    /* cursor: pointer; */
    position: relative;
    overflow: hidden;
}
.para-item ul li{
    cursor: pointer; 
}
.para-item.ayata-item ul li{
    cursor: inherit;  
}
.revelation .card .card-header:hover,
.revelation .card .card-header.sellect,
.para-item ul li:hover,
.para-item ul li.sellect {
    background-color: #fffae0;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}
.revelation .card:after,
.para-item ul li:after {
    content: "";
    width: 80%;
    height: 2px;
    background: url(../images/para-border.png) no-repeat;
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: 100%;
    margin: auto;
}

.para-item.ayata-item ul li:after {
    width: 50%;
}
.revelation-icon,
.para-number {
    background-image: url(../images/para-number-bg.png);
    width: 50px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    background-size: 100%;
    background-repeat: no-repeat;
}
.revelation-icon span,
.para-number span {
    color: #894306;
    font-size: 16px;
}

.surah-number-no, .para-number-no {
    color: #2a4722;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    width: 65%;
    font-family: 'Kalpurush', sans-serif;
}

.full-play-name {
    font-size: 20px;
    font-weight: 600;
    color: #333537;
}

.surah-number-no span,
.para-number-no span {
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.surah-number-no span.meaning_bn,  .para-number-no span.meaning_bn{
    /* margin-bottom: 3px; */
}

.surah-number-no span.total_ayat_bn,  .para-number-no span.total_ayat_bn{
    font-size: 14px;
}

.surah-item ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.surah-item-left {
    display: flex;
    align-items: center;
    width: 62%;
}

.surah-item-right {
    font-size: 22px;
    font-weight: 500;
    color: #2a4722;
    padding-right: 8px;
    font-family: qalamQuranMajeed;
}

.ayata-item ul>li {
    padding: 15px;
}

.ayata-title {
    text-align: right;
    color: #2a4722;
    font-size: 22px;
    font-weight: 500;
    display: block;
    margin-bottom: 24px;
    padding: 0 0 0 80px;
    line-height: 32px;
    font-family: qalamQuranMajeed;
}

.ayata-item-start {
    padding: 0 0 0 7%;
}

.ayata-item-start ul {
    background: transparent;
    box-shadow: none;
}

.ayata-item-start ul li {
    font-size: 18px;
    color: #828481;
    margin: 0;
    text-align: left;
    position: relative;
    display: flex;
    padding: 0 0 8px 0;
    line-height: 28px;
    font-weight: 500;
    font-family: 'Kalpurush', sans-serif;
}
.ayata-item-box .ayat_no_single {
    padding: 0 0 4px 0;
    position: relative;
    z-index: 1;
    margin: 0 0 0 55px;
}

.ayata-item-start ul li.bangla {
    color: #2a4722;
}

.ayata-item-start ul li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

.ayata-item-start ul li span {
    position: absolute;
    left: 0;
    width: 40px;
    text-align: center;
    align-items: start;
    top: 0;
    font-size: 16px;
    font-weight: 500;
}

.ayata-item-start ul li:after {
    display: none;
    opacity: 0;
}
/* 
.re-play, .pause-button, .play-button {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 1px solid transparent;
    z-index: 9;
} */

.re-play, .pause-button, .play-button {
    position: absolute;
    width: 60px;
    height: 80px;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    background-color: transparent;
    border: 1px solid transparent;
    z-index: 9;
}

.play-button span {
    background-image: url(../images/play.png);
    width: 20px;
    height: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    top: 30px;
    display: block;
    position: absolute;
    left: 15px;
}

.play-button span {
    background-image: url(../images/play.png);
    width: 20px;
    height: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    top: 30px;
    display: block;
    position: absolute;
    left: 15px;
}

.pause-button span {
    background-image: url(../images/pause.png);
    width: 20px;
    height: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    top: 30px;
    display: block;
    position: absolute;
    left: 15px;
}

.ayata-item-box {
    width: 100%;
}

.full-play {
    position: relative;
    overflow: hidden;
}

.full-play-inner {
    background-image: url(../images/full-play.png);
    background-repeat: no-repeat;
    background-size: 100% 95%;
    height: 75px;
    position: fixed;
    /* overflow: hidden; */
    right: 26px;
    bottom: 0;
    left: 304px;
    top: auto;
    z-index: 9;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}

.active-loop {
    border: 1px solid transparent;
    background-color: transparent;
    background-image: url(../images/active-loop.png);
    width: 28px;
    height: 18px;
    background-size: 100%;
    background-repeat: no-repeat;
    top: 25px;
    display: block;
    position: absolute;
    left: auto;
    right: 42px;
}

.no-Loop {
    border: 1px solid transparent;
    background-color: transparent;
    background-image: url(../images/no-Loop.png);
    width: 28px;
    height: 18px;
    background-size: 100%;
    background-repeat: no-repeat;
    top: 25px;
    display: block;
    position: absolute;
    left: auto;
    right: 42px;
}

.play-normal {
    width: 100px;
    display: flex;
    align-items: center;
    margin: 24px 0 0 0;
}

.play-normal-btn {
    background-image: url(../images/play-normal-btn.png);
    background-color: transparent;
    border: 1px solid transparent;
    width: 26px;
    height: 26px;
    background-size: 90%;
    background-repeat: no-repeat;
}

.play-normal span {
    font-size: 15px;
    color: #333537;
    padding-left: 5px;
    font-weight: 400;
}

.full-play-surah {
    display: flex;
    align-items: center;
    padding: 8px 0 0 15px;
}

.full-play-btn-area {
    height: 75px;
}

.full-play-btn-area, .full-play-btn-area ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.full-play-btn-area ul li {
    align-items: center;
}

.full-play-btn-left, .full-play-btn-play-pause, .full-play-btn-right {
    background-color: transparent;
    border: 1px solid transparent;
    background-repeat: no-repeat;
}

.full-play-btn-left {
    background-image: url(../images/full-play-btn-left.png);
    width: 25px;
    height: 18px;
    background-size: 90%;
}

.full-play-btn-play-pause {
    background-image: url(../images/full-play-btn-play-pause.png);
    width: 58px;
    height: 58px;
    background-size: 100%;
    position: relative;
    z-index: 99;
}

.pause-dropdown-block .pause-button {
    background-image: url(../images/full-play-btn-play-pause1.png);
    width: 58px;
    height: 58px;
    background-size: 100%;
    position: relative;
    z-index: 99;
}

.pause-dropdown-block .pause-button span {
    opacity: 0;
    visibility: hidden;
}

.full-play-btn-right {
    background-image: url(../images/full-play-btn-right.png);
    width: 25px;
    height: 18px;
    background-size: 90%;
    margin-left: 8px;
}

.full-play-btn-area ul li span {
    color: #333537;
    font-size: 18px;
    padding: 0 0 0 24px;
}

.pause-dropdown-block {
    display: flex;
    align-items: center;
}

.pause-dropdown-block .show>.btn-primary.dropdown-toggle, .pause-dropdown-block button.btn-primary:focus, .pause-dropdown-block button.btn-primary:hover, .pause-dropdown-block button.btn-primary {
    border: 1px solid #fff;
    background-color: #fff;
    color: #333537;
    /* width: 275px; */
    max-width: 275px;
    padding: 5px 5px 3px 36px;
    font-size: 16px;
    color: #333537;
    font-size: 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 1px 4px #ebeded;
    border-radius: 25px;
    margin-left: -35px;
    font-weight: 500;
}

.speed-control .show>.btn-primary.dropdown-toggle:focus, .speed-control .btn-primary.dropdown-toggle:focus .pause-dropdown-block .show>.btn-primary.dropdown-toggle:focus, .pause-dropdown-block .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
    border: 1px solid #fff;
    background-color: #fff;
    color: #333537;
}

.pause-dropdown-block .dropup .dropdown-toggle::after {
    border-top: 0.3em solid;
    border-bottom: 0 solid;
    right: 0;
    position: relative;
}

.pause-dropdown-block .dropdown-menu {
    width: 235px;
    left: 30px !important;
    border: 1px solid #ebeded;
    border-bottom: 0;
    box-shadow: none;
    bottom: -4px !important;
    border-radius: 0.25rem 0.25rem 0 0 !important;
}

.speed-control .dropdown-item, .pause-dropdown-block .dropdown-item {
    padding: 0.6rem 0.8rem 0.35rem;
    font-size: 15px;
}

.speed-control .dropdown-item.active, .speed-control .dropdown-item:active, .speed-control .dropdown-item:hover, .pause-dropdown-block .dropdown-item.active, .pause-dropdown-block.dropdown-item:active, .pause-dropdown-block .dropdown-item:hover {
    background-color: #fffae0;
    color: #333537;
}

.speed-control {
    display: flex;
    align-items: center;
}

.speed-control .show .btn-primary.dropdown-toggle, .speed-control button.btn-primary:focus, .speed-control button.btn-primary:hover, .speed-control button.btn-primary {
    background-image: url(../images/play-normal-btn.png);
    background-color: transparent;
    border: 1px solid transparent;
    width: 26px;
    height: 26px;
    background-size: 90%;
    background-repeat: no-repeat;
}

.speed-control .dropup .dropdown-toggle::after {
    opacity: 0;
    display: none;
}

.speed-control .btn-primary:focus, .speed-control .btn-primary.focus {
    box-shadow: unset
}

.speed-control-text {
    padding-left: 5px;
}

.speed-control-text span {
    display: block;
    font-size: 15px;
    color: #333537;
    font-weight: 400;
}

#boxscroll {
    height: 368px;
    padding-bottom: 80px;
    width: 100%;
}

#boxscroll2 {
    height: 368px;
    overflow: auto;
    padding-bottom: 80px;
    width: 100%;
}

#boxscroll3 {
    overflow: auto;
    height: 368px;
    padding-bottom: 80px;
    width: 100%;
}

/*============ sidebar nav open style ============*/

.sidebar-nav .navbar-item ul li span {
    display: block;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    /* -webkit-transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
    transition: all 900ms ease; */
}

.sidebar-nav .menu-heading {
    display: none;
    opacity: 0;
}

.sidebar-nav .sidebar {
    width: 70px;
}

.sidebar-nav #header {
    left: 0
}

.sidebar-nav .full-play-inner {
    left: 102px;
}

.sidebar-nav .main-content {
    left: 70px;
}

.logo .open-nav {
    display: block;
    opacity: 1;
}

.sidebar-nav .logo .open-nav {
    display: none;
    opacity: 0;
}

.logo .close-nav {
    display: none;
    opacity: 0;
    width: 50px;
    height: 50px;
}

.sidebar-nav .logo .close-nav {
    display: block;
    opacity: 1;
}

.sidebar-nav .logo {
    padding: 20px 20px 32px 10px;
}
.sidebar-nav .metismenu .has-arrow::after{
    right: 0.5em;
    width: .3em;
    height: .3em;
    top: 42%;
}
.sidebar-nav .navbar-item .sub-menu li a {
    padding-left: 45px;
}
.sidebar-nav .navbar-item .sub-menu li a i {
    font-size: 10px !important;
    top: -4px;
}
.para-item {
    /* min-height: 400px; */
    padding-bottom: 15px;
}

.navbar-scroll-container {
    height: calc(100vh - 162px) !important;
}

.mb60 {
    margin-bottom: 60px;
}

/*============ Download Sectin style ============*/

.download-section-title {
    color: #15172a;
    font-size: 36px;
    line-height: 48px;
    font-weight: 600;
    font-family: 'Hind Siliguri', sans-serif;
    text-align: center;
    margin-bottom: 12px;
}

.download-item ul li, .download-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.download-heading {
    border-bottom: 1px solid #f1f1f1;
    padding: 28px 10px 15px 70px;
}

.download-item-para, .download-item-list, .download-heading-right {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.download-item-para {
    justify-content: flex-end;
}

.download-heading h5 {
    color: #15172a;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    font-family: 'Hind Siliguri', sans-serif;
}

.download-section-inner {
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 1px;
    box-shadow: 0 2px 3px 2px #f2f2f2;
    border-radius: 4px;
    max-width: 1024px;
    margin: auto;
}

.download-item {
    padding: 0;
    box-shadow: none;
    margin: 0;
}

.download-item ul {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    padding-bottom: 60px;
}

.download-item ul li {
    display: flex;
    padding: 8px 30px 6px 22px;
    border-bottom: 1px solid #f1f1f1;
}

/* .download-item-list {
    width: auto;
} */
.download-item-list {
    width: auto;
    width: 263px;
    justify-content: start;
    min-width: 250px;
}

.bangla-download, .arbe-download {
    border: 2px solid #2a4722;
    border-radius: 25px;
    height: 40px;
    width: 130px;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    color: #2a4722;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Hind Siliguri', sans-serif;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}

.bangla-download i, .arbe-download i {
    display: inline-block;
}
.bangla-download span, .arbe-download span{
    display: inline-block;
    margin: 0 5px;
} 

.bangla-download {
    color: #894306;
    border-color: #894306;
    margin-left: 50px;
}

.para-item.download-item ul li:first-child:after {
    opacity: 0;
    display: none;
}

.para-item.download-item ul li:after {
    content: "";
    width: 40%;
    height: 2px;
    background: url(../images/para-border.png) no-repeat;
    position: absolute;
    display: block;
    bottom: auto;
    background-size: 100%;
    margin: auto;
    display: block;
    left: 0;
    right: 0;
    top: 0;
}

.download-item ul li:hover .bangla-download {
    color: #fff;
    border-color: #894306;
    background-color: #894306;
}

.download-item ul li:hover .arbe-download {
    color: #fff;
    border-color: #2a4722;
    background-color: #2a4722;
}

/*============ Download Other Sectin style ============*/

.download-other-section .download-section-inner {
    margin-bottom: 12px;
}

.download-other-section .download-item-para {
    width: auto;
}

.download-other-section .para-item.download-item ul {
    margin-bottom: -2px;
}

.download-other-section .para-item.download-item ul li {
    border: 0;
    padding: 14px 15px 12px 22px;
}

.download-other-section .para-item.download-item ul li:after {
    display: none;
    opacity: 0;
}

.download-other-section .para-item.download-item ul:after {
    content: "";
    width: 65%;
    height: 2px;
    background: url(../images/para-border.png) no-repeat;
    position: absolute;
    display: block;
    bottom: 1px;
    background-size: 100%;
    margin: auto;
    display: block;
    left: 0;
    right: 0;
    top: auto;
}

.download-other-section .download-heading {
    padding: 28px 10px 15px 16px;
    width: 95%;
    margin: auto;
}

.alquran-icon {
    background-image: url(../images/alquran.png);
}

.mp3-icon {
    background-image: url(../images/mp3.png);
}

.text-icon {
    background-image: url(../images/text-icon.png);
}

.text2-icon {
    background-image: url(../images/text2-icon.png);
}

.loading-block {
    position: relative;
    overflow: hidden;
}

.section-loading {
    background-color: #fff;
    position: relative;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 190px - 120px) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.player-loading {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 70px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*============ Kalema Page style ============*/

.kalema-bengali-pronunciation {
    color: #828481;
}

.kalema-bengali-meaning {
    color: #2a4722;
}

.kalema-bengali-pronunciation {
    color: #828481;
}

.kalema-english-meaning {
    color: #2a4722;
}

.kalema-english-meaning b {
    color: #828481;
    font-weight: normal;
    font-size: 14px;
}

.message_scroll_loading {
    padding: 15px 0 0 0;
}

.message_scroll_loading i {
    font-size: 20px;
    color: #965924;
}

/*============ Dua Page style ============*/

.nano-dua .ayata-title, .nano-dua .ayata-item-start {
    padding: 0;
}

/*============ Hadiths Page style ============*/

.hadiths-item {
    /* border: 1px solid #f00; */
}

.hadiths-item .surah-item-left, .hadiths-item .surah-number-no {
    width: 100%;
    font-size: 18px;
}

.hadiths-item .surah-number-no span {
    display: block;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
}

.hadiths-item .para-number {
    width: 70px;
    height: 65px;
    margin-right: 12px;
    background-size: 100%;
}

.hadiths-item .para-number span {
    color: #894306;
    font-size: 20px;
    position: relative;
    top: -2px;
}

.hadiths-title {
    display: block;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 10px;
}

.hadiths-details {
    padding: 0 0 0 32px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
}

.hadiths-details strong {
    display: block;
    margin-bottom: 10px;
}

.ayata-item-box-sura-info {
    position: absolute;
    width: 54%;
    text-align: center;
    top: 5px;
    left: 0;
    right: 0;
    margin: auto;
    padding: 5px 0 0 0;
}

.sura-start-section {
    padding-top: 90px !important;
}

.sura-start-section .re-play, .sura-start-section .pause-button, .sura-start-section .play-button {
    top: 24px;
}




.sura_name_bn {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.sura_name_ar {
    padding: 0;
    font-size: 22px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 5px;
    font-family: qalamQuranMajeed;
}
.kalema-item-det .ayata-item-start ul li{
    padding-left: 0;
}
.kalema-item-det .ayata-item-start {
    padding: 0 0 0 8%;
}
.kalema-item-det .ayata-item ul>li {
    padding-bottom: 24px;
}
.revelation .card {
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 0;
    border: 0;
    box-shadow: 0 2px 3px 2px #f2f2f2;
    border-radius: 4px;
}
.revelation .card-header{
    padding: 0;
    margin: 0;
    background-color: #fff;
}
button.revelation-button:focus,
button.revelation-button:hover,
button.revelation-button {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    text-align: left;
    color: #2a4722;
    text-decoration: none;
    padding: 14px 8px;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    width: 100%;
}
.revelation-text{
    width: 78%;
}
.chapter-block {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
}
.chapter-title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
}
.chapter-block-left {
    min-width: 85px;
}
.collapse-icon {
    font-size: 18px;
    color: #894306;
    position: absolute;
    right: 14px;
    top: 15px;
}

.collapse-icon .fa-chevron-circle-down{
    display: block;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}
.card_active .collapse-icon .fa-chevron-circle-down{
    display: block;
    transform: rotate(-180deg);
} 
.revelation-Body {
    padding: 15px 0 0 65px;
    background-color: #fff;
}
.revelation-Body ul li a, .revelation-Body ul li span {
    color: #894306;
    display: block;
    padding: 15px 14px;
    font-size: 14px;
    font-weight: 600;
    border-top: 1px solid #ebeff2;
    border-bottom: 1px solid #ebeff2;
}
.revelation-Body ul li:last-child a{
    border-bottom: 0;
}
/*============ Namaz Timing Page style ============*/

.namaz-timing-sellect .para-title {
    font-size: 32px;
}
.namaz-timing {
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 1px;
    box-shadow: 0 2px 3px 2px #f2f2f2;
    border-radius: 4px;
}
.namaz-timing .para-item ul {
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 0;
    box-shadow: unset;
    border-radius: 0;
}
.namaz-timing-sellect{
 margin-bottom: 5px;
}
.namaz-timing .para-item ul li,
.namaz-timing-heading,
.namaz-timing-sellect {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.namaz-timing-heading span{
    font-size: 18px;
    line-height: 20px;
    color: #15172a;
    font-weight: 600;
    display: block;
    font-family: 'Hind Siliguri', sans-serif;  
}

.namaz-timing .para-item{
    background: #fff;
    padding: 0 0 30px 0;
    box-shadow: unset;
    border-radius: 0;
}
.namaz-timing-heading,
.namaz-timing .para-item ul li{
  padding: 20px 24px;
}
.namaz-timing .para-item ul li:after {
    width: 60%;
}
.namaz-time,
.namaz-name{
    font-size: 24px;
    color: #2a4722;
    font-weight: 400;
    display: block;
    font-family: 'Hind Siliguri', sans-serif;  
}
.namaz-time{
    color: #2a4722;
}
.select-css {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: #15172a;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #dfdfdf;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url(../images/select-array.png), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);    
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}

.select-css::-ms-expand {
    display: none;
}

.select-css:hover {
    border-color: #888;
}

.select-css:focus {
    color: #222;
    outline: none;
}

.select-css option {
    font-weight: normal;
}

.disclaimer{
    padding: 20px;
}
.disclaimer ul {
    margin:0;
    padding:0 0 20px 24px;
}
.disclaimer ul li:hover,
.disclaimer ul li {
    padding: 0;
    color: #2a4722;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    list-style-type: circle;
    background-color: transparent;
    border: 0
}
.disclaimer ul li:after{
    content: none !important;
    background: none !important;
}
.disclaimer p {
    color: #2a4722;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 24px;
}
.disclaimer p strong{
    display: block;
    margin-bottom: 8px;
}

/*============ Search Result style ============*/
.form-check-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.check-result small {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    display: block;
    line-height: 30px;
}
.check-result {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .check-result input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 26px;
    background-color: #fffae0;
    border: 2px solid #767367;
    border-radius: 3px;
  }

  .check-result input:checked ~ .checkmark {
    background-color: #894307;
    border-color: #894307;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .check-result input:checked ~ .checkmark:after {
    display: block;
  }
  
  .check-result .checkmark:after {
    left: 7px;
    top: 2px;
    width: 9px;
    height: 17px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .all-result-heading {
    padding: 12px 24px;
    font-size: 20px;
    color: #838383;
    font-style: italic;
    font-weight: 500;
}
.result-list {
    width: 100%;
    display: flex;
    align-items: start;
}
.result-list-no{
    color: #6a6a6a;
    /* width: 45px; */
    text-align: left;
    font-size: 18px;
    line-height: 27px;
}
.result-list-inner{
    padding: 0 0 0 28px;
    width: 100%;
}
.result-list-inner-no{
    display: block;
    margin-bottom: 10px;
}
.result-list-inner-no span {
    color: #6a6a6a;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    margin-right: 24px;
    line-height: 24px;
}
.result-list-inner p {
    color: #6a6a6a;
    text-align: left;
    font-size: 20px;
    margin: 0;
    padding: 0;
    line-height: 30px;
}
.result-list-inner p.arabic-result {
    text-align: right;
    margin-bottom: 10px;
    font-family: qalamQuranMajeed;
}
.result-list-inner p strong{
    color: #000;  
}
.searchresult-btn.active,
.searchresult-btn:focus,
.searchresult-btn:hover,
.searchresult-btn{
    background-color: #4eb250 !important;
    border-color: #4eb250 !important;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    font-size: 18px;
    color: #fff !important;
}
.advanced-search .form-control:focus,
.advanced-search .form-control{
    border-color: #ebedee;
    height: 50px;
    background-color: #f9fafb;
    box-shadow: none;
    font-size: 16px;
    color: #838383;
}
.advanced-search .form-label {
    display: block;
    width: 100%;
    font-size: 20px;
    color: #0b0b0b;
    margin-bottom: 15px;
    font-weight: 500;
}
.advanced-search .form-row{
    margin-bottom: 15px;
}
.advanced-search-modal .modal-dialog{
    max-width: 672px;
}
.advanced-search{
    padding: 14px 14px 0 14px;
}
.modal-title {
    font-size: 32px;
    color: #15172a;
    font-weight: 600;
    display: block;
    font-family: 'Hind Siliguri', sans-serif;
    line-height: 30px;
}
.district-loading{
    text-align: center;
}


/*============ Loop Modal Style ============*/
.Loop-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .Loop-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .Loop-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #fffae0;
    border: 2px solid #8e632c;
  }
  
  .Loop-radio input:checked ~ .checkmark {
    background-color: #fffae0;
  }

  .Loop-radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .Loop-radio input:checked ~ .checkmark:after {
    display: block;
  }

  .Loop-radio .checkmark:after {
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #894307;
    border-color: #894307;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  .Loop-modal .modal-title {
    font-size: 24px;
    line-height: 34px;
}
.Loop-moda-section{
    padding: 24px 24px 0 24px;
}

.Loop-moda-section ul li {
    line-height: 28px;
    margin-bottom: 15px;
    display: block;
}
.Loop-moda-section ul li .Loop-radio stong {
    font-size: 18px;
    position: relative;
    top: -2px;
}
.Loop-modal .btn.btn-secondary:focus,
.Loop-modal .btn.btn-secondary:hover,
.Loop-modal .btn.btn-secondary {
    background-color: #d5d9d8;
    border-color: #d5d9d8;
    color: #333537;
    padding: 12px 24px;
    font-weight: 600;
    box-shadow: none !important;
}
.Loop-modal .btn.btn-primary:focus,
.Loop-modal .btn.btn-primary:hover,
.Loop-modal .btn.btn-primary {
    background-color: #fffae0;
    border-color: #fffae0;
    color: #333537;
    padding: 12px 24px;
    font-weight: 600;
    box-shadow: none !important;
}

.ranges-Loop .form-group{
    margin-bottom: 0;
}
.ranges-Loop .form-control:focus,
.ranges-Loop .form-group input {
    border: 1px solid #d5d9d8;
    position: relative;
    opacity: 1;
    height: 44px;
    background-color: #f1f1f1;
    outline: 0;
    box-shadow: none;
}

.revelation-Body-new ul li {
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.revelation-Body-new .card:after,
.revelation-Body-new ul li:after{
    opacity: 0;
    display: none;

}
.revelation-Body-new ul{
    box-shadow: none;
    margin-bottom: 10px;
}
.revelation-Body-new ul li span{
    width: 100%;
}

/*============ For Mobile Style ============*/
.mobile-section {
    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
}
.top-section{
    background-image: url(../images/mobile/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 460px;
}
.mobile-logo {
    width: 200px;
    margin: auto;
    margin-bottom: 10px;
    padding: 40px 0 0 0;
}
.bottom-logo {
    width: 130px;
    margin: auto;
    margin-top: -50px;
}
.bottom-section{
    margin-bottom: 50px;
}
.bottom-text {
    text-align: center;
    padding: 22px 0 12px;
    display: block;
    overflow: hidden;
}
.bottom-text p {
    font-size: 34px;
    color: #325528;
    font-weight: 600;
    display: block;
    font-family: 'Hind Siliguri', sans-serif;
    line-height: 42px;
}
.app-link {
    display: flex;
    justify-content: center;
}
.app-link a {
    display: block;
    width: 152px;
    margin: 0 10px;
}
.Loop-radio .text-left.text-danger {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
}

.names-of-allah .ayata-item-start ul li{
    line-height: 22px;
    padding: 0 0 5px 15px;
    font-size: 16px;
}
.names-of-allah .ayata-title{
    padding: 0 15px 0 50px;
    margin-bottom: 10px;
}
.names-of-allah .kalema-bengali-pronunciation strong,
.names-of-allah .kalema-bengali-meaning strong{
    /* border: 1px solid #f00; */
    font-size: 16px;
}
.names-of-allah .re-play,
.names-of-allah .pause-button,
.names-of-allah .play-button{
    height: 50px; 
}
.names-of-allah .re-play span,
.names-of-allah .pause-button span,
.names-of-allah .play-button span{
    top: 15px;
}

.custom-search-input {
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    z-index: 9;
}
.custom-search-input .form-control{
    border-color: #ebedee;
    height: 60px;
    font-size: 18px;
    color: #000;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e5e6e7;
    outline: 0;
    box-shadow: none;
}
.search-custom-btn:focus,
.search-custom-btn:hover,
.search-custom-btn {
    background-color: #e5e6e7 !important;
    border-color: #e5e6e7 !important;
    width: 70px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    background-image: url(../images/search-icon.png);
    background-repeat: no-repeat;
    background-size: 34px;
    background-position: center center;
    outline: 0 !important;
    box-shadow: none !important;
}

.h-90{
    height: 64vh !important;
}
.names-of-allah .para-item ul li {
    cursor: auto;
}
.CusScrollbars > div {
    overflow-x: hidden !important;
}

/*============ Contact us style ============*/
.contact-block-wrap{
    max-width: 600px;
    margin: auto;
}
.contact-block{
   padding: 24px;
}
.contact-block .form-group {
    margin-bottom: 1rem;
    padding:5px;
}
.contact-block .form-control{
    height: 60px;
    background-color: #f5f5f5;
    border: 2px solid #f0f0f0;
    border-radius: 8px;
    padding-right: 20px;
    padding-left: 20px;
}
.contact-block textarea{
    height: auto;
    min-height:110px;
}
.contact-btn:hover,
.contact-btn{
    color: #fff;
    background-color: #2a4722;
    border-color: #2a4722;
    width: 240px;
    height: 50px;
    font-size: 18px;
    line-height: 40px;
    margin-bottom: 70px;
}
.alquran_error_textarea,
.alquran_error_input{
 border:2px solid #f00 !important;   
}
.contact-block .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #f0f0f0;
    outline: 0;
    box-shadow: none;
}
.contact-block label {
    color: #dc3545;
    font-size: 16px;
    margin-bottom: 5px;
}

.contact-block .error-msg,
.contact-block .success-msg {
    display: block;
    font-size: 20px;
    padding: 5px;
    text-align: center;
}
.auth-section{
    padding: 5px 5px 8px 5px;
}
.auth-section-inner {
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 1px;
    box-shadow: 0 1px 3px 2px #f2f2f2;
    border-radius: 4px;
    min-height: 400px;
}
.auth-section-inner .action_form{
    max-width: 550px;
    margin: auto;
    padding: 45px 15px 80px;
}
.auth-section .para-title {
    font-size: 34px;
    font-weight: 600;
}
.auth-section-inner .form-control{
    box-shadow: 0 0 2px 2px rgba(209, 209, 209, 0.3);
    height: 48px;
    border-color: #dfdfdf;
    font-family: 'Hind Siliguri', sans-serif;
    color: #9c9c9c;
    font-size: 20px;
}
 .auth-button:hover,
 .auth-button{
    color: #fff;
    background-color: #2a4722;
    border-color: #2a4722;
    width: 140px;
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 40px;
    margin: auto auto 20px auto;
    line-height: 50px;
    position: relative;
    font-family: 'Hind Siliguri', sans-serif;
}
.auth-button-group{
    position: relative;
    overflow: hidden;
}
.auth-button-group::after {
    content: "";
    width: 80%;
    height: 2px;
    background: url(../images/auth-bg.png) no-repeat;
    position: absolute;
    display: block;
    bottom: auto;
    background-size: 100%;
    margin: auto;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
}
.auth-section-inner .lost-password{
    margin: 8px 0 30px 0;
}
.auth-section-inner .lost-password a {
    position: relative;
    width: 100%;
    text-align: right;
    color: #894306;
    display: block;
    font-size: 14px;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 500;
}

.register-link{
 color: #2a4722;
 font-size: 20px;
 font-weight: 500;
 font-family: 'Hind Siliguri', sans-serif;
}
.register-link a{
 color: #894306;
}
.new-password-note {
    color: #2a4722;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.reg-message {
    background-color: #fffbe8;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
    margin-top: -26px;
}
.reg-message p {
    color: #894306;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: 'Hind Siliguri', sans-serif;
}
.reg-message p:last-child{
    margin: 0;
}
.auth-profile ul li{
    display: block;
    position: relative;
    margin-bottom: 40px;
    font-size: 20px;
    color: #15172a;

}
.auth-profile ul li label{
    display: block;
    color: #9c9c9c;
    font-family: 'Hind Siliguri', sans-serif;
}
.auth-profile-edit {
    color: #9c9c9c;
    position: absolute;
    right: 0;
    font-size: 24px;
    bottom: 0;
}
.auth-profile-save {
    color: #15172a;
    position: absolute;
    right: 0;
    font-size: 16px;
    bottom: 6px;
    padding: 8px 15px 5px 15px;
    background-color: #f1f1f1;
    border-radius: 24px;
    font-family: 'Hind Siliguri', sans-serif;
}
.auth-section-inner .auth-profile .form-control {
    box-shadow: none;
    height: 30px;
    border-color: #dfdfdf;
    font-family: 'Hind Siliguri', sans-serif;
    color: #9c9c9c;
    font-size: 20px;
    border: 0;
    border-bottom: 1px solid #f1f1f1;
    border-radius: 0;
    padding-left: 0;
}
.profile-change-password{
   margin:24px auto auto auto ;
}
.profile-change-password a{
    color: #894306;
    font-size: 20px;
    font-family: 'Hind Siliguri', sans-serif;
}

.page-not-found-thumb {
    /* width: 360px; */
    margin: auto auto 20px auto;
    max-width: 360px;
}
.page-not-found-info h1 {
    color: #15172a;
    font-size: 48px;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 500;
    line-height: 55px;
    display: block;
    margin-bottom: 20px;
}
.page-not-found-info p {
    color: #15172a;
    font-size: 24px;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 500;
    line-height: 30px;
    display: block;
    margin-bottom: 30px;
}
.page-not-found-info a {
    color: #894306;
    font-size: 24px;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 500;
    line-height: 30px;
    display: block;
    margin-bottom: 30px;
}
/* ========= New Hadith Search ========== */
.new-hadith-search-form {
    max-width: 800px;
    margin: auto;
    border-bottom: 1px solid #f1f1f1;
    position: relative;
    padding: 24px 100px 24px;
    margin-bottom: 8px;
}
.new-hadith-search-form form {
    position: relative;
}
.new-hadith-search-form .form-control {
    border-color: #dfdfdf;
    border-radius: 10px;
    height: 40px;
    padding-right: 70px;
    color: #969696;
    font-family: 'Hind Siliguri', sans-serif;
}
.new-hadith-search-form .search-custom-btn-new:hover,
.new-hadith-search-form .search-custom-btn-new{
    background-color: #2a4722;
    border-color: #2a4722;
    border-radius: 10px;
    height: 40px;
    width: 65px;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 0;
}
.new-hadith-search-content {
    padding: 10px 20px 15px 30px;
}

.new-hadith-search-content ul li{
  padding: 6px;
  display: inline-block;
  width: 20%;
  border: 0;
}
.new-hadith-search-content.new-bukhari-hadiths ul li{
    width: 25%;
}
.new-hadith-search-content ul li:hover{
    background-color: transparent !important;
}
.new-hadith-search-content ul li a {
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 2px 3px 2px #f2f2f2;
    border: 1px solid transparent;
    border-radius: 4px;
    margin: 0 0 0 0;
    height: 88px;
    padding: 10px 5px 10px 15px;
    display: block;
    display: flex; 
    align-items: center;
    justify-self: flex-start;
}
.new-hadith-search-content.new-bukhari-hadiths ul li a{
    height: 100px; 
}
.new-hadith-search-content ul li a:hover{
    border: 1px solid #2a4722;
}
.new-hadith-search .para-item.download-item ul li:after{
    display: none !important;
    opacity: 0 !important;
}
.new-hadith-search-content ul li a strong{
    color: #565656;
    font-size: 18px;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 500;
    line-height: 24px;
    display: block;
    padding: 5px 0;
    /* width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}
.new-hadith-search-content ul li a span{
    color: #878787;
    font-size: 16px;
    /* font-family: 'Hind Siliguri', sans-serif; */
    font-weight: 500;
    line-height: 18px;
    display: block;
    margin-bottom: 5px; 
}
.new-hadith-search-list-inner{
    padding: 12px;
}
.new-hadith-search-list-inner .hadith-search-list-inner-block h4{
    color: #894306;
    font-size: 16px;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 500;
    line-height: 18px;
    display: block;
    margin-bottom: 5px; 

}
.new-hadith-search-list-inner .hadith-search-list-inner-block h4 a{
    color: #894306;
}
.new-hadith-search-list-inner .hadith-search-list-inner-block h5{
    color: #2a4722;
    font-size: 16px;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 500;
    line-height: 18px;
    display: block;
    margin-bottom: 10px; 

}
.new-hadith-search-list-inner .hadith-search-list-inner-block h5 a{
    color: #2a4722;
}
.new-hadith-search-list-inner .hadith-search-list-inner-block p{
    margin-bottom: 10px;
}
.new-hadith-search-list-inner .hadith-search-list-inner-block p a{
    color: #15172a;
    font-size: 14px;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 500;
    line-height: 18px;
    display: block;
}
.new-hadith-search-list-inner .hadith-search-list-number{
    color: #878787;
    font-size: 14px;
    /* font-family: 'Hind Siliguri', sans-serif; */
    font-weight: 500;
    line-height: 18px;
    display: block;
    margin-bottom: 5px; 
    display: block;
}

.download-item .new-hadith-search-list-inner ul li {
    padding: 8px 30px 15px 22px;
}

.box-shadow-none{
    box-shadow: none;
    border: 0;
}
.new-hadith-search-form-details{
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 0;
    box-shadow: 0 2px 3px 2px #f2f2f2;
    border-radius: 4px;
    max-width: 1024px;
    margin: auto auto 10px auto;
}
.new-hadith-search-form-details .new-hadith-search-form{
    border: 0;
    padding: 7px 100px;
}
.new-hadith-search-details-item{
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 20px 24px;
    box-shadow: 0 2px 3px 2px #f2f2f2;
    border-radius: 4px;
    max-width: 1024px;
    margin: auto auto 10px auto;
}
.new-hadith-search-details-item .heading{
    width: 95%;
}
.new-hadith-search-details-item .heading h4{
    color: #894306;
    font-size: 20px;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 500;
    line-height: 24px;
    display: block;
    margin-bottom: 15px;
}
.new-hadith-search-details-item .heading h5{
    color: #2a4722;
    font-size: 20px;
    /* font-family: 'Hind Siliguri', sans-serif; */
    font-weight: 500;
    line-height: 24px;
    display: block;
    margin-bottom: 18px;
}
.new-hadith-search-details-item .details-item-info {
    color: #894306;
    font-size: 18px;
    display: block;
    position: absolute;
    top: 20px;
    right: 36px;
}
.new-hadith-search-details-item .new-hadith-search-details-rtl p{
    text-align: right;
    font-size: 16px;
    font-family: qalamQuranMajeed;
}
.new-hadith-search-details-item .new-hadith-search-details-bangla p {
    font-family: 'Kalpurush', sans-serif;
    font-size: 16px;
    color: #15172a;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 10px;
    padding-bottom: 8px;
    line-height: 22px;
    font-weight: 600;
}
.new-hadith-search-details-item .new-hadith-search-details-bangla p:last-child{
    margin: 0;
    padding: 0;
    border: 0;
}